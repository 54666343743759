import React, { useState, useEffect } from "react";
import axios from "axios";
import { TbCircleNumber1 } from "react-icons/tb";
import { Tooltip } from 'react-tooltip'
import {format, formatDistanceToNow, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import { VscReferences } from "react-icons/vsc";

import 'react-tooltip/dist/react-tooltip.css'
import { GiPlainCircle } from "react-icons/gi";
import { CiRead } from "react-icons/ci";
import styles from './styles/tooltip.module.css'; // Import CSS module styles
import Zoom from 'react-medium-image-zoom'
import Recherche from "./Recherche";
import "./styles/datatable.css"
function InterventionsTab() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [interventions, setInterventions] = useState([]);
  const [isLoadingInterventions, setIsLoadingInterventions] = useState(true); // State to manage loading status
  const ASSETSURL = process.env.REACT_APP_BACKEND_ASSETS;
  const navigate = useNavigate();
  const displayValeurs = (valeurReferentiel) => {
    const valeurs = [];
    
    let current = valeurReferentiel;
    while (current) {
      const tooltipId = `tooltip-valeur-${current.id}`;
      valeurs.push(
        <span key={current.id} data-tooltip-id={tooltipId}>
          {current.valeur}
          <Tooltip id={tooltipId} style={{zIndex:999999999999999}}>
            <div>
              <strong>{current.valeur}</strong>
              
              <br />
           <ul>
 
  {current.valeur && (
    <li>
      <span>Valeur: {current.valeur}</span>
    </li>
  )}
 
  {current.created_at && (
    <li>
      <span>Créé le: {current.created_at}</span>
    </li>
  )}
 
  {current.contact && (
    <li>
      <span>Contact: {current.contact}</span>
    </li>
  )}
  {current.adresse && (
    <li>
      <span>Adresse: {current.adresse}</span>
    </li>
  )}
  {current.code_postal && (
    <li>
      <span>Code Postal: {current.code_postal}</span>
    </li>
  )}
  {current.ville && (
    <li>
      <span>Ville: {current.ville}</span>
    </li>
  )}
  {current.pays && (
    <li>
      <span>Pays: {current.pays}</span>
    </li>
  )}
  {current.coordonnees && (
    <li>
      <span>Coordonnées: {current.coordonnees}</span>
    </li>
  )}
  {current.description && (
    <li>
      <span>Description: {current.description}</span>
    </li>
  )}
  {current.infos_site && (
    <li>
      <span>Infos Site: {current.infos_site}</span>
    </li>
  )}
  {current.latitude_longitude && (
    <li>
      <span>Latitude/Longitude: {current.latitude_longitude}</span>
    </li>
  )}
  {current.site && (
    <li>
      <span>Site: {current.site}</span>
    </li>
  )}
  {current.statut && (
    <li>
      <span>Statut: {current.statut}</span>
    </li>
  )}
</ul>


              
              {/* Add more fields from current if needed */}
            </div>
          </Tooltip>
        </span>
      );
      current = current.parent;
    }
  
    return valeurs.reduce((acc, curr) => [acc, ' > ', curr]);
  };

  // State to manage which tooltip is open
  const [openTooltipId, setOpenTooltipId] = useState(null);

  const handleZoomClose = () => {
    // Close the tooltip when zoom is closed
    setOpenTooltipId(null);
  };

  const handleTooltipClick = (id) => {
    // Toggle the tooltip open/close state
    if (openTooltipId === id) {
      setOpenTooltipId(null); // Close the tooltip
    } else {
      setOpenTooltipId(id); // Open the tooltip
    }
  };
  const columns = [
    {
      name: '',
      selector: row => {
        const color = row.sold_at 
          ? '#ff0000' 
          : row.started_at
          ? '#ffa500' 
          : row.finished_at
          ? '#00bfff' 
          : '#7239ea'; 
        
        return row.user ? <GiPlainCircle color={color} /> : ''; 
      },
      width: '50px',
    },
       
    {
      name: '',
      selector: row => {
        const getFirstAndLastValeur = (valeurReferentiel) => {
          const valeurs = [];
          let current = valeurReferentiel;
    
          // Parcours des parents jusqu'à la racine
          while (current) {
            valeurs.unshift(current.valeur);  // Ajoute la valeur au début
            current = current.parent;
          }
    
          // Si plus de 2 valeurs, affiche la première et la dernière avec '...'
          if (valeurs.length > 2) {
            return `${valeurs[0]} > ... > ${valeurs[valeurs.length - 1]}`;
          } else if (valeurs.length === 2) {
            // Si seulement 2 valeurs, retourne première et dernière sans '...'
            return `${valeurs[0]} > ${valeurs[1]}`;
          } else {
            return valeurs[0];  // Si une seule valeur, retourne simplement cette valeur
          }
        };
    
        // Vérifie si la donnée `valeur_referentiel` existe
        const valeurHierarchy = row.valeur_referentiel 
          ? getFirstAndLastValeur(row.valeur_referentiel) 
          : '';
    
        return (
          <>
            <a
              href="#"
              className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6"
              data-tooltip-id={`tooltip-${row.id}`} // Set data-for with tooltip ID
              onClick={() => handleTooltipClick(`tooltip-${row.id}`)} // Toggle tooltip on click
              id={`tooltip-${row.id}`}
              place="top"
              effect="solid"
              key={`tooltip-${row.id}`}
            > #{row.id + " | "}  
              {valeurHierarchy}  {/* Affiche la première et la dernière valeur avec '... > ' */}
            </a>
            <span className="text-muted fw-semibold d-block">
              {row.intervention_type?.name}
            </span>
          </>
        );
      },
      // minWidth: '200px',
      width:"340px"
    }
    
    
,    
    {
      name: '',
      selector: row => row.user ? row.user.nom : '',

      minWidth: '140px',
      right: true,
    },
    {
      name: '',
      selector: row => {
        // Debugging: Log the entire row object and fields
        console.log('Row data:', row);
    
        const downloadedAt = row.downloaded_at;
        const soldAt = row.sold_at;
        const startedAt = row.started_at;
    
        console.log('downloaded_at:', downloadedAt);
        console.log('sold_at:', soldAt);
        console.log('started_at:', startedAt);
    
        let status;
    
        if (soldAt) {
          status = 'Réalisée'; // If 'sold_at' is not null
        } else if (startedAt) {
          status = 'Démarrée'; // If 'started_at' is not null
        } else if (downloadedAt) {
          status = 'Synchronisée'; // If 'downloaded_at' is not null
        } else {
          status = 'Planifié'; // If none of the above fields are set
        }
    
        console.log('Assigned status:', status);
       
        return (
          <span className={`badge ${status === 'Réalisée' ? 'badge-light-danger' : status === 'Démarrée' ? 'badge-light-warning' : status === 'Synchronisée' ? 'badge-light-info' : 'badge-light-success'}`}>
            {status}
          </span>
        );
      },
      width: '115px',
      right: true,
    },     
    {
      name: '',
      selector: row => (
        <button
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
          onClick={() => navigate(`/interventiondetail/${row.id}`)}
        >
          <i className="ki-duotone ki-arrow-right fs-2">
            <CiRead />
          </i>
        </button>
      ),
      width: '70px',
      right: true,
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [filters, setFilters] = useState({
    form_id: '',
    afterDate: '',
    afterText: '',
    beforeDate: '',
    beforeText: '',
    date: '',
    description: '',
    flag_id: '',
    time: '',
    typeInt: '',
    unit: '',
    withAppointment: '',
    downloaded_at: '',
    valeur_referentiels_id: '',
    started_at: '',
    sold_at: '',
    status: '',
    user_id: '',
    intervention_type_id: '',
    datefin: '',
  });
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');

  const fetchInterventions = async (page) => {
    setIsLoadingInterventions(true);
    try {
      const response = await axios.get(`${apiHost}/interventionsFront`, {
        params: { 
          page, 
          limit: 10, 
          filters,
          sortBy,
          sortOrder,
        }
      });
      setInterventions(response.data.data);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error('Error fetching interventions:', error);
    } finally {
      setIsLoadingInterventions(false);
    }
  };

  useEffect(() => {
    fetchInterventions(currentPage);
  }, [currentPage, filters, sortBy, sortOrder]);

 

  const handleSort = (column) => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortBy(column);
    setSortOrder(order);
  };
  const data = interventions.map(intervention => ({
    id: intervention.id,
    description: intervention.description,
    user :intervention.user,
    downloaded_at: intervention.downloaded_at,
    started_at: intervention.started_at,
    sold_at: intervention.sold_at,
    intervention_type:intervention.intervention_type,
    valeur_referentiel:intervention.valeur_referentiel
  }));
  return (
    <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1" >
      <Recherche filters={filters} setFilters={setFilters} ></Recherche>
       
   <DataTable
    noHeader={true}
    columns={columns}
        data={interventions}
        pagination
        paginationServer
        paginationTotalRows={totalPages * 10}
        onChangePage={page => setCurrentPage(page)}
        progressPending={isLoadingInterventions}
        progressComponent={<div className="text-center">Loading...</div>}
        noDataComponent="No data available"
        resizableColumns={true}
        onSort={(column, sortDirection) => handleSort(column.selector)}
        sortServer
      />

      {/* Tooltips */}
      {interventions.map(intervention => (
        
        <Tooltip
      className="extra"
      opacity={1}
      style={{
        backgroundColor: 'white',
        zIndex: 999,
        padding: '0',
        maxHeight: '480px',
        width: '520px',
        overflowY: 'scroll',
        pointerEvents: 'auto'
      }}
      border="1px solid red"
      closeEvents={["click","mouseleave "]} // Disable auto-close events
      // events={['click']} // Disable auto-close events
      isOpen={openTooltipId === `tooltip-${intervention.id}`} // Control open state
      // onClick={() => handleTooltipClick(`tooltip-${intervention.id}`)} // Toggle tooltip on click
      id={`tooltip-${intervention.id}`}
      place="top"
      effect="solid"
      key={`tooltip-${intervention.id}`}
    >
      <div className="card">
        {/*begin::Header*/}
        <div className={`card-header border-0 ${styles.fixedHeader}`}>
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-900">
              {intervention.description}
            </span>
          </h3>
          <div className="card-toolbar">
            <span className="text-muted mt-1 fw-semibold fs-7">
              {format(new Date(intervention.created_at), 'MM/dd/yyyy')} (
              {`Il y a ${formatDistanceToNow(parseISO(intervention.created_at), { locale: fr })}`})
            </span>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div style={{ marginLeft: '5px' }}>
          <p>JH83+QXQ, Aïn Harrouda, Morocco</p>
          <table
            className="mb-4"
            style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}
          >
            <tr>
              <td style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>
                0 km/h
              </td>
              <td style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>x</td>
              <td style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>
                <span
                  className={`badge ${
                    intervention.sold_at
                      ? 'badge-light-danger'
                      : intervention.started_at
                      ? 'badge-light-warning'
                      : intervention.downloaded_at
                      ? 'badge-light-info'
                      : 'badge-light-success'
                  } me-2`}
                >
                  {intervention.sold_at ? (
                    <span>Réalisée</span>
                  ) : intervention.started_at ? (
                    <span>Démarrée</span>
                  ) : intervention.downloaded_at ? (
                    <span>Synchronisée</span>
                  ) : (
                    <span>Planifié</span>
                  )}
                </span>
              </td>
              <td style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>
                <VscReferences /> {displayValeurs(intervention.valeur_referentiel)}
              </td>
            </tr>
          </table>

          <table
            style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}
          >
            <tr>
              <th
                style={{ background: '#f6f6f6', fontWeight: 400, padding: '7px 0 7px 10px' }}
                className="td"
                colSpan="2"
                dir="auto"
              >
                Formulaire ( {intervention.form.name }):
              </th>
            </tr>
            {intervention.intervention_fields.map(field => (
              <tr key={field.field.name}>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  {field.field.name}
                </td>
                <td style={{ border: '1px solid black', padding: '8px' }}>
                  {field.value !== null && field.field.fieldtype.name === 'image' ? (
                    <Zoom onZoomOut={handleZoomClose}>
                      <img
                        src={ASSETSURL + field.value}
                        alt={field.field.name}
                        style={{ maxWidth: '100px' }}
                      />
                    </Zoom>
                  ) : (
                    field.value
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
        {/*end::Body*/}
      </div>
    </Tooltip>
      ))}
    </div>
  );
}

export default InterventionsTab;
