import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CiSearch, CiSettings } from "react-icons/ci";
import Select from "react-select";

function Recherche({filters,setFilters}) {
  const apiHost = process.env.REACT_APP_API_HOST;

  //Population Variable
  const [forms, setForms] = useState([]);
  const [flags, setFlags] = useState([]);
  const [interventionTypes, setInterventionTypes] = useState([]);
  const [users, setUsers] = useState([]); // Nouvel état pour les utilisateurs

  //Population Functions 
  const fetchForms = async () => {
    try {
      const response = await fetch(`${apiHost}/forms`);
      const data = await response.json();
      // Mapper les formulaires pour qu'ils soient compatibles avec react-select
      const options = data.data.map((form) => ({
        value: form.id, // Assumes each form has an `id` field
        label: form.name, // Utilise `name` pour l'étiquette visible
      }));
      setForms(options);
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  };
  const fetchflags = async () => {
    try {
      const response = await fetch(`${apiHost}/flags`);
      const data = await response.json();
      // Mapper les flags pour qu'ils soient compatibles avec react-select
      const options = data.map((flag) => ({
        value: flag.id, // Assume que chaque flag a un `id`
        label: flag.name, // Utilise `name` pour l'étiquette visible
      }));
      setFlags(options); // Met à jour les options pour react-select
    } catch (error) {
      console.error('Error fetching flags:', error);
    }
  };

  const fetchInterventionTypes = async () => {
    try {
      const response = await fetch(`${apiHost}/intervention-types`);
      const data = await response.json();
      const options = data.map((type) => ({
        value: type.id,
        label: type.name,
      }));
      setInterventionTypes(options);
    } catch (error) {
      console.error('Error fetching intervention types:', error);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiHost}/users`);
      const data = await response.json();
      const options = data.map((user) => ({
        value: user.id,
        label: user.nom, // Assumes each user has a `name` field for display
      }));
      setUsers(options);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  //Fetching 
  useEffect(()=>{
    fetchForms();
    fetchflags();
    fetchInterventionTypes(); 
    fetchUsers();

  },[]);
  
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (selectedOption, fieldName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: selectedOption ? selectedOption.value : null, // Mettre à jour le champ correspondant
    }));
  };
  const handleDateChange = (date, fieldName) => {
    const formattedDate = date ? format(date, "yyyy-MM-dd") : null; // Formater la date
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: formattedDate, // Utiliser le nom du champ pour la mise à jour
    }));
  };
  const appointmentOptions = [
    { value: '1', label: 'Oui' }, // Oui
    { value: '0', label: 'Non' }, // Non
  ];
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  return (
    <>
    {/*begin::Form*/}
    <form action="#">
      {/*begin::Card*/}
      <div className="card ">
        {/*begin::Card body*/}
        <div className="card-body">
          {/*begin::Compact form*/}
          <div className="d-flex align-items-center">
            {/*begin::Input group*/}
            <div className="position-relative w-md-400px me-md-2">
              <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-6">
              <CiSearch  />
             
              </i>
              <input
                type="text"
                className="form-control form-control-solid ps-10"
                name="description"
                value={filters.description}
                onChange={handleFilterChange}
                 placeholder="Recherche"
              />
            </div>
            {/*end::Input group*/}
            {/*begin:Action*/}
            <div className="d-flex align-items-center">
              <button type="button" className="btn btn-primary me-5">
                Recherche
              </button>
              <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_advanced_Recherche_form"
                  aria-expanded="false"
                  aria-controls="kt_advanced_Recherche_form"
                >
                  <CiSettings /> Autre Filtres
                </button>
            </div>
            {/*end:Action*/}
            
          </div>
          {/*end::Compact form*/}
          {/*begin::Advance form*/}
         <div className="collapse" id="kt_advanced_Recherche_form">
  {/*begin::Separator*/}
  <div className="separator separator-dashed mt-9 mb-6" />
  {/*end::Separator*/}
  {/*begin::Row with your filters*/}
  <div className="row g-4 mb-4 filter-container">
  {/* Begin Filter Inputs */}
  <div className="col-lg-6">
    <div className="mb-3">
      <label className="form-label">Formulaire</label>
      <Select
        value={forms.find(option => option.value === filters.form_id) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'form_id')}
        options={forms}
        placeholder="Sélectionner un Formulaire"
        isClearable
        classNamePrefix="react-select"
      />
    </div>

    <div className="mb-3 d-flex flex-column ">
  <label className="form-label me-2">Date Après</label>
  <DatePicker
    selected={filters.afterDate ? new Date(filters.afterDate) : null}
    onChange={(date) => handleDateChange(date, 'afterDate')}
    dateFormat="yyyy-MM-dd"
    className="form-control flex-grow-1" // Utilise flex-grow-1 pour que le DatePicker prenne toute la largeur restante
    placeholderText="Filtrer par Date Après"
    isClearable
  />
</div>


    <div className="mb-3">
      <label className="form-label">Texte Après</label>
      <input
        type="text"
        className="form-control"
        name="afterText"
        placeholder="Filtrer par Texte Après"
        value={filters.afterText}
        onChange={handleFilterChange}
      />
    </div>

    <div className="mb-3 d-flex flex-column ">
      <label className="form-label">Date Avant</label>
      <DatePicker
        selected={filters.beforeDate ? new Date(filters.beforeDate) : null}
        onChange={(date) => handleDateChange(date, 'beforeDate')}
        dateFormat="yyyy-MM-dd"
        className="form-control"
        placeholderText="Filtrer par Date Avant"
        isClearable
      />
    </div>

    <div className="mb-3">
      <label className="form-label">Texte Avant</label>
      <input
        type="text"
        className="form-control"
        name="beforeText"
        placeholder="Filtrer par Texte Avant"
        value={filters.beforeText}
        onChange={handleFilterChange}
      />
    </div>

   
  </div>

  <div className="col-lg-6">
    

    <div className="mb-3">
      <label className="form-label">Drapeaux</label>
      <Select
        value={flags.find(option => option.value === filters.flag_id) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'flag_id')}
        options={flags}
        placeholder="Sélectionner un Flag"
        isClearable
        classNamePrefix="react-select"
      />
    </div>

    <div className="mb-3">
      <label className="form-label">Rendez-vous</label>
      <Select
        value={appointmentOptions.find(option => option.value === filters.withAppointment) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'withAppointment')}
        options={appointmentOptions}
        placeholder="Sélectionner Rendez-vous"
        isClearable
        classNamePrefix="react-select"
      />
    </div>

    <div className="mb-3">
      <label className="form-label">ID de l'utilisateur</label>
      <Select
        value={users.find(option => option.value === filters.user_id) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'user_id')}
        options={users}
        placeholder="Sélectionner un Utilisateur"
        isClearable
        classNamePrefix="react-select"
      />
    </div>

    <div className="mb-3">
      <label className="form-label">Type d'intervention</label>
      <Select
        value={interventionTypes.find(option => option.value === filters.intervention_type_id) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'intervention_type_id')}
        options={interventionTypes}
        placeholder="Sélectionner un Type d'intervention"
        isClearable
        classNamePrefix="react-select"
      />
    </div>
  </div>
  {/* End Filter Inputs */}
</div>


  {/*end::Row*/}
</div>


          {/*end::Advance form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}
    </form>
    {/*end::Form*/}
  </>
  );
}

export default Recherche;
