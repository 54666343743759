import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const apiHost = process.env.REACT_APP_API_HOST;

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiHost}/login`, {
        email,
        password,
      });

      // Save token in localStorage
      localStorage.setItem("token", response.data.access_token);

      // Redirect to dashboard or another page
      navigate("/dashboard");
    } catch (error) {
      setError("Échec de la connexion. Veuillez vérifier vos identifiants.");
    }
  };

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="d-flex flex-lg-row-fluid">
        {/*begin::Content*/}
        <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
          {/*begin::Image*/}
          <img
            className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
            src="assets/media/logos/SFSBIG.png"
            alt=""
          />

          {/*end::Image*/}
          {/*begin::Title*/}
          <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">
            Gérez vos interventions efficacement
          </h1>
          {/*end::Title*/}
          {/*begin::Text*/}
          <div className="text-gray-600 fs-base text-center fw-semibold">
            Connectez-vous pour accéder à la gestion des interventions,
            techniciens, et bien plus encore.
          </div>
          {/*end::Text*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*begin::Aside*/}
      {/*begin::Body*/}
      <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
        {/*begin::Wrapper*/}
        <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
          {/*begin::Content*/}
          <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
            {/*begin::Wrapper*/}
            <div className="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">
              {/*begin::Form*/}
              <form
                className="form w-100"
                noValidate="novalidate"
                onSubmit={handleLogin} // Connecte le formulaire à handleLogin
              >
                {/*begin::Heading*/}
                <div className="text-center mb-11">
                  {/*begin::Title*/}
                  <h1 className="text-gray-900 fw-bolder mb-3">
                    Authentification
                  </h1>
                  {/*end::Title*/}
                </div>

                {/*begin::Input group=*/}
                <div className="fv-row mb-8">
                  {/*begin::Email*/}
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Met à jour l'état email
                  />
                  {/*end::Email*/}
                </div>
                {/*end::Input group=*/}

                <div className="fv-row mb-3">
                  {/*begin::Password*/}
                  <input
                    type="password"
                    placeholder="Mot de passe"
                    name="password"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} // Met à jour l'état password
                  />
                  {/*end::Password*/}
                </div>
                {/*end::Input group=*/}

                {/* Gestion des erreurs */}
                {error && (
                  <div className="text-danger text-center mb-3">{error}</div>
                )}

                {/*begin::Submit button*/}
                <div className="d-grid mb-10">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-primary"
                  >
                    <span className="indicator-label">Se connecter</span>
                    <span className="indicator-progress">
                      Veuillez patienter...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                </div>
                {/*end::Submit button*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::Body*/}
    </div>
  );
};

export default LoginPage;
