import React, { useState, useEffect } from 'react';
// import { LoadScript } from '@react-google-maps/api';

const GoogleMapsLoader = ({ children }) => {
  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    if (!window.google || !window.google.maps) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDLsf_Bg2fD6QlB2DpIFWgsOJ4JX8CWXO8&region=MA`;
      script.async = true;
      script.defer = true;
      script.onload = () => setApiLoaded(true);
      document.head.appendChild(script);
    } else {
      setApiLoaded(true);
    }
  }, []);

  return apiLoaded ? (
      children
  ) : (
    <div>LoadingGooooogle...</div>
  );
};

export default GoogleMapsLoader;
